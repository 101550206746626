import styled from '@emotion/styled';
import { breakpoints, spacing } from '../../../../utils/styleguide';
import SectionTitle from '../../SectionTitle';
import Container from '../Container';
import PartnerCaseCard from './PartnerCaseCard';
import { Document } from '@contentful/rich-text-types';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing[5]}px;
  flex-wrap: wrap;

  margin-top: ${spacing[5]}px;

  ${breakpoints.desktop} {
    padding-left: ${spacing[5]}px;
  }
`;

type Props = {
  sectionTitle?: string;
  partnerCases?: {
    logo?: string;
    image?: string;
    text?: Document;
    link?: {
      title?: string;
      url?: string;
    };
  }[];
  backgroundColor?: string;
};

export default function PartnerCasesLayout({ sectionTitle, partnerCases, backgroundColor }: Props) {
  if (!sectionTitle && !partnerCases?.length) return null;

  return (
    <Container backgroundColor={backgroundColor}>
      {sectionTitle && <SectionTitle title={sectionTitle} />}

      {!!partnerCases?.length && (
        <ContentWrapper>
          {partnerCases.map((partnerCase, index) => (
            <PartnerCaseCard
              key={index}
              partnerCase={partnerCase}
              nrOfCases={partnerCases.length}
            />
          ))}
        </ContentWrapper>
      )}
    </Container>
  );
}
