import styled from '@emotion/styled';
import { Entry } from 'contentful';
import { css } from '@emotion/react';
import { format as formatDate, parseISO as parseISODate } from 'date-fns';
import { TypeBlogPostSkeleton } from '../../../../../@types/generated';
import { colors, spacing } from '../../../../utils/styleguide';
import Image from '../../Image';
import GhostButton from '../../buttons/GhostButton';
import Typography from '../../text/Typography';

const CardWrapper = styled.div`
  display: flex;
  min-width: 227px;
  max-width: 560px;
  width: -webkit-fill-available;
  height: 464px;
  flex-direction: column;
  gap: ${spacing[4]}px;
  margin: 0 ${spacing[2]}px;

  .slick-slide {
    flex: 1;
  }
`;

const CopyWrapper = styled.div`
  display: flex;
  padding: 0 ${spacing[4]}px;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 0;
  align-self: stretch;
  border-left: 1px solid ${colors.grey03};
`;

const TypographyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[1]}px;
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 50%;
  width: 100%;
  overflow: hidden;
`;

const ImageStyles = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default function ArticleCarouselCard({
  article,
}: {
  article: Entry<TypeBlogPostSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string> | undefined;
}) {
  if (!article) return null;

  const { title, publishedAt, slug, externalLink, image } = article.fields;

  const handleOnClickCard = () => {
    if (externalLink) {
      window.open(externalLink, '_blank');
    } else {
      window.open(`/articles/${slug}`);
    }
  };

  const imageUrl = image?.fields?.file?.url;

  return (
    <CardWrapper>
      <ImageWrapper>
        {imageUrl ? (
          <Image lazyLoad css={ImageStyles} url={imageUrl} alt={`${image?.fields?.description}`} />
        ) : (
          <div
            css={{ bgcolor: 'purple.main', position: 'relative', width: '100%', height: '100%' }}
          />
        )}
      </ImageWrapper>

      <CopyWrapper>
        <TypographyWrapper>
          <Typography variant="overline" color="text.secondary">
            {formatDate(parseISODate(publishedAt), 'MMM dd, yyyy')}
          </Typography>

          <Typography maxNumberOfLines={5} variant="h6Bold">
            {title}
          </Typography>
        </TypographyWrapper>

        <GhostButton
          label={'Read more'}
          trailingIconName="arrow-right"
          color={colors.purple400}
          hideUnderline
          onClick={handleOnClickCard}
          css={{ padding: `${spacing[1]}px 0` }}
        />
      </CopyWrapper>
    </CardWrapper>
  );
}
