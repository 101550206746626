import styled from '@emotion/styled';
import SectionTitle from '../SectionTitle';
import Container from './Container';
import { breakpointQueries, breakpoints, colors, spacing } from '../../../utils/styleguide';
import Typography from '../text/Typography';
import PrimaryButton from '../buttons/PrimaryButton';
import Divider from '../Divider';
import { useMediaQuery } from '../../../utils/hooks/useMediaQuery';
import RichText from '../../contentful-elements/RichText/RichText';
import { Document } from '@contentful/rich-text-types';

const ContentWrapper = styled.div`
  padding-left: 0;

  ${breakpoints.desktop} {
    padding-left: ${spacing[5]}px;
  }
`;

const Content = styled.div`
  ${breakpoints.desktop} {
    display: flex;
    flex-direction: row;
  }
`;

const ContentSection = styled.div`
  flex: 1;
`;

const Title = styled(Typography)`
  margin-top: ${spacing[6]}px;
  margin-bottom: ${spacing[5]}px;
`;

const StyledRichText = styled(RichText)`
  margin-top: ${spacing[3]}px;
`;

const CTA = styled(PrimaryButton)`
  margin-top: ${spacing[4]}px;
`;

const StyledDivider = styled(Divider)`
  margin: ${spacing[4]}px 0;
  ${breakpoints.desktop} {
    margin: 0 ${spacing[6]}px;
  }
`;

export default function FormCTA({
  ctaFormSectionTitle,
  ctaFormTitle,
  ctaFormSections,
  backgroundColor,
}: {
  ctaFormSectionTitle?: string;
  ctaFormTitle?: string;
  ctaFormSections?: {
    title?: string;
    description?: Document | undefined;
    link?: {
      title?: string;
      url?: string;
    };
  }[];
  backgroundColor?: string;
}) {
  const [isDesktop] = useMediaQuery(breakpointQueries.desktop);

  if (!ctaFormSections?.length) return null;

  return (
    <Container backgroundColor={backgroundColor}>
      <SectionTitle title={ctaFormSectionTitle ?? ''} />
      <ContentWrapper>
        <Title variant="h3Bold">{ctaFormTitle}</Title>
        <Content>
          <ContentSection>
            <Typography variant="overline" color={colors.blackSecondary}>
              {ctaFormSections[0].title}
            </Typography>
            <StyledRichText document={ctaFormSections[0].description} />
            <CTA
              size="small"
              trailingIconName="chevron-right"
              label={ctaFormSections[0].link?.title}
              href={ctaFormSections[0].link?.url}
            />
          </ContentSection>
          <StyledDivider vertical={isDesktop} />
          <ContentSection>
            <Typography variant="overline" color={colors.blackSecondary}>
              {ctaFormSections[1].title}
            </Typography>
            <StyledRichText document={ctaFormSections[1].description} />
            <CTA
              size="small"
              variant="outline"
              label={ctaFormSections[1].link?.title}
              href={ctaFormSections[1].link?.url}
            />
          </ContentSection>
        </Content>
      </ContentWrapper>
    </Container>
  );
}
