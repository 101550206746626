import styled from '@emotion/styled';
import { spacing, colors, breakpoints } from '../../utils/styleguide';
import Image from './Image';

const LogoInnerWrapper = styled.div<{ divider: boolean }>`
  flex: 0 1 50%;
  padding: ${spacing[3]}px 0;
  margin-bottom: ${spacing[5]}px;
  text-align: center;
  box-sizing: border-box;
  object-fit: contain;
  border-right: ${({ divider }) => (divider ? `1px solid ${colors.grey03}` : 'none')};

  ${breakpoints.tablet} {
    flex: 0 1 33%;
  }
  ${breakpoints.desktop} {
    flex: 0 1 20%;
  }
`;

const LogoImage = styled(Image)`
  height: ${spacing[5]}px;
  width: auto;
  object-fit: contain;
  filter: brightness(0) saturate(100%) invert(7%) sepia(0%) saturate(542%) hue-rotate(148deg)
    brightness(98%) contrast(101%);
  opacity: 0.7;
  ${breakpoints.tablet} {
    height: ${spacing[6]}px;
  }
`;

type LogoProps = {
  link?: string;
  logoUrl?: string;
  fileName?: string;
  divider: boolean;
};

export default function Logo({ link, logoUrl, fileName, divider }: LogoProps) {
  if (!logoUrl) {
    return null;
  }

  if (!link) {
    return (
      <LogoInnerWrapper divider={divider}>
        <LogoImage url={logoUrl} fileName={fileName} />
      </LogoInnerWrapper>
    );
  }

  return (
    <LogoInnerWrapper divider={divider}>
      <a href={link}>
        <LogoImage url={logoUrl} fileName={fileName} />
      </a>
    </LogoInnerWrapper>
  );
}
