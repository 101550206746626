import styled from '@emotion/styled';
import { spacing } from '../../../utils/styleguide';
import { ImageProps } from '../Image';
import { useByMediaQuery } from '../../../utils/hooks/useMediaQuery';
import SectionTitle from '../SectionTitle';
import Container from './Container';
import Logo from '../Logo';

const LogoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${spacing[5]}px;
  justify-content: 'flex-start';
  flex: 1;
`;

type Props = {
  title?: string;
  logosList?: {
    link?: string;
    image?: ImageProps;
  }[];
  backgroundColor?: string;
};

const PartnerLogosLayout = ({ title, logosList, backgroundColor }: Props) => {
  const logosPerRow = useByMediaQuery<number>({ mobile: 2, tablet: 3, desktop: 5 });

  if (!logosList?.length) return null;

  return (
    <Container backgroundColor={backgroundColor}>
      {title && <SectionTitle title={title} />}
      {logosList && (
        <LogoWrapper>
          {logosList.map((logo, index) => (
            <Logo
              key={logo.image?.url}
              link={logo.link}
              logoUrl={logo.image?.url}
              fileName={logo.image?.fileName}
              divider={!!logosPerRow && (index + 1) % logosPerRow !== 0}
            />
          ))}
        </LogoWrapper>
      )}
    </Container>
  );
};

export default PartnerLogosLayout;
