import { breakpoints, colors, spacing } from '../../../utils/styleguide';
import styled from '@emotion/styled';
import Typography from '../text/Typography';
import PrimaryButton from '../buttons/PrimaryButton';
import SvgImage from '../SvgImage';
import SectionTitle from '../SectionTitle';
import Image from '../Image';
import { css } from '@emotion/react';
import { Document } from '@contentful/rich-text-types';
import RichText from '../../contentful-elements/RichText/RichText';
import Container from './Container';
import { TypeLink } from '../../../../@types/generated';
import GhostButton from '../buttons/GhostButton';

const ContentWrapper = styled.div<{ rightAlignedCopy?: boolean }>`
  display: flex;
  flex-direction: column;
  padding-left: 0;
  gap: ${spacing[6]}px;
  margin-top: ${spacing[5]}px;

  ${breakpoints.desktop} {
    flex-direction: ${({ rightAlignedCopy }) => (rightAlignedCopy ? 'row-reverse' : 'row')};
    padding-left: ${spacing[5]}px;
  }
`;

const LeftColWrapper = styled.div<{ allImagesContainImageUrls?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${breakpoints.desktop} {
    width: ${({ allImagesContainImageUrls }) => (allImagesContainImageUrls ? '50%' : '100%')};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[2]}px;
  margin-top: ${spacing[4]}px;
`;

const ImageStack = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[2]}px;
  position: relative;
  width: 100%;

  ${breakpoints.desktop} {
    width: 50%;
    height: auto;
    min-height: 300px;
  }
`;

const ImageOverlay = styled.div`
  border-radius: 2px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  background: linear-gradient(199deg, rgba(0, 0, 0, 0) 66.64%, rgba(0, 0, 0, 0.4) 90.27%);
`;

const ImageStyle = css`
  border-radius: 2px;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageCopyWrapper = styled.div`
  display: flex;
  gap: ${spacing[2]}px;
  align-items: center;
  bottom: 0;
  left: 0;
  position: absolute;
  padding: ${spacing[1]}px;
  color: ${colors.white};
`;

type Props = {
  rightAlignedCopy?: boolean;
  sectionTitle?: string;
  text?: Document;
  buttons?: TypeLink[];
  buttonVariant?: 'primary' | 'text';
  images?: {
    imageUrl?: string;
    imageText?: string;
    imageIconUrl?: string;
  }[];
  backgroundColor?: string;
};

export default function HighlightLayout({
  rightAlignedCopy,
  sectionTitle,
  text,
  buttons,
  buttonVariant = 'primary',
  images,
  backgroundColor,
}: Props) {
  if (!sectionTitle && !text && !buttons?.length) return null;

  const allImagesContainImageUrls = Boolean(
    images?.length && images.every((image) => !!image.imageUrl),
  );

  return (
    <Container backgroundColor={backgroundColor}>
      {sectionTitle && <SectionTitle title={sectionTitle} />}

      <ContentWrapper rightAlignedCopy={rightAlignedCopy}>
        <LeftColWrapper allImagesContainImageUrls={allImagesContainImageUrls}>
          <RichText document={text} withCheckmark />

          {buttons && buttonVariant === 'primary' && (
            <ButtonWrapper>
              {buttons?.map((button, index) => (
                <PrimaryButton
                  key={index}
                  color="purple"
                  size="small"
                  style={{ width: 'fit-content' }}
                  label={button.fields.identifier}
                  variant={index === 0 ? 'solid' : 'outline'}
                  trailingIconName="chevron-right"
                  href={button.fields.externalUrl}
                />
              ))}
            </ButtonWrapper>
          )}

          {buttons && buttonVariant === 'text' && (
            <ButtonWrapper>
              {buttons?.map((button, index) => (
                <GhostButton
                  key={index}
                  label={button.fields.identifier}
                  trailingIconName="chevron-right"
                  color={colors.purple400}
                  hideUnderline
                  onClick={() => window.open(button.fields.externalUrl ?? undefined, '_blank')}
                />
              ))}
            </ButtonWrapper>
          )}
        </LeftColWrapper>

        {!!images?.length && allImagesContainImageUrls && (
          <ImageStack>
            {images.map((image, index) => (
              <div
                key={index}
                style={{
                  position: 'relative',
                  minHeight: images?.length > 1 ? '150px' : '350px',
                  height: images.length === 1 ? '100%' : `${100 / images.length}%`,
                }}
              >
                <Image lazyLoad url={image.imageUrl ?? ''} css={ImageStyle} />
                {image.imageText && (
                  <>
                    <ImageOverlay />
                    <ImageCopyWrapper key={index}>
                      {image.imageIconUrl && (
                        <SvgImage src={image.imageIconUrl} color={colors.white} size="24px" />
                      )}
                      <Typography variant="overlineSmall" color={colors.white}>
                        {image.imageText}
                      </Typography>
                    </ImageCopyWrapper>
                  </>
                )}
              </div>
            ))}
          </ImageStack>
        )}
      </ContentWrapper>
    </Container>
  );
}
